<template>
  <v-container fill-height class="countdown">
    <v-row class="justify-center">
      <v-col class="align-center justify-center" cols="12">
        <div class="text-center text-capitalize countdown__title text-decoration-underline mb-4">Epitech ends in</div>
        <div class="text-center countdown__seconds">{{ seconds }} Seconds</div>
        <div class="text-center countdown__minutes">{{ minutes }} Minutes</div>
        <div class="text-center countdown__hours">{{ hours }} Hours</div>
        <div class="text-center countdown__days">{{ days }} Days</div>
        <div class="text-center countdown__weeks">{{ weeks }} Weeks</div>
      </v-col>
      <v-col cols="8" sm="6" md="4" class="mt-10">
        <v-progress-linear
          :value="percentage"
          rounded
          height="15"
          color="black"
        >
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    seconds: null,
    minutes: null,
    hours: null,
    days: null,
    weeks: null,
    percentage: null,
    start: new Date("October 25, 2021 00:00:00"),
    end: new Date(`July 28, 2023 23:59:59`),
  }),

  methods: {
    update() {
      let current = new Date();

      let diff_current = (this.end.getTime() - current.getTime()) / 1000;
      let diff_start = (current.getTime() - this.start.getTime()) / 1000;
      let diff_total = (this.end.getTime() - this.start.getTime()) / 1000;

      this.seconds = Math.abs(Math.round(diff_current));
      this.minutes = Math.abs(Math.round(diff_current / 60));
      this.hours = Math.abs(Math.round(diff_current / (60 * 60)));
      this.days = Math.abs(Math.round(diff_current / (60 * 60 * 24)));
      this.weeks = Math.abs(Math.round(diff_current / (60 * 60 * 24 * 7)));

      this.percentage = (diff_start * 100) / diff_total;
    },
  },
  beforeMount() {
    const promo = Number(this.$route.query.y);
    this.end = new Date(`July 28, ${promo} 23:59:59`);
    this.update();

    setInterval(this.update, 100);
  },
};
</script>

<style lang="scss">
.countdown {
  font-family: "Poppins", sans-serif;

  &__seconds {
    font-size: 3em;
    font-weight: 700;
  }

  &__minutes {
    font-size: 2.5em;
    font-weight: 500;
  }

  &__hours {
    font-size: 2em;
    font-weight: 400;
  }

  &__days {
    font-size: 1.5em;
    font-weight: 300;
  }

  &__weeks {
    font-size: 1em;
    font-weight: 200;
  }

  &__title {
    font-size: 2.5em;
    font-weight: 600;
  }
}
</style>
